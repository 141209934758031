import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import MuiGrid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loader from '../../../Loaders/ComponentLoader';

import ChartTooltip from './Tooltip';
import { getSiteInvertersAndSensors } from '../../../../helpers';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import { useSelector } from 'react-redux';
import WebAPIClient from '../../../../api';
import { prepareEstimatedProductionTimeseriesData } from '../../../../helpers/chart-data';
import SelectNumber from '../../selectors/SelectNumber';
import { generateCsvString } from '../../../../helpers/csv-data';
import { displaykW } from '../../../../helpers/display-energy';

const AREAS = [
  {
    key: 'radiation',
    name: 'Irradiance',
    yAxisId: 'right',
  },
];

const LINES = [
  {
    key: 'production',
    name: 'Production (actual)',
    yAxisId: 'left',
  },
  {
    key: 'powerEstimate',
    name: 'Production (estimated)',
    yAxisId: 'left',
  },
  {
    key: 'temp',
    name: 'Panel Temperature',
    yAxisId: 'left',
  },
];

const sumByType = (chartData, type) =>
  reduce(
    chartData,
    (acc, point) =>
      acc +
      reduce(
        point,
        (acc, val, key) => {
          if (key === type) {
            return acc + val * 0.25;
          }
          return acc;
        },
        0
      ),
    0
  );

const calculateTotals = (chartData) => {
  return {
    actual: sumByType(chartData, 'production'),
    estimated: sumByType(chartData, 'powerEstimate'),
    irradiance: sumByType(chartData, 'radiation'),
  };
};

const prepareCsvData = (data) => {
  let headers = [
    'timestamp',
    'production',
    'radiation',
    'temp',
    'powerEstimate',
  ];

  return generateCsvString(
    headers,
    map(data, (values) => {
      return {
        ...values,
        timestamp: dayjs(values.timestamp).format(),
      };
    })
  );
};

function EstimatedChart({ site, range, timezone, fetch, setFetch }) {
  const theme = useTheme();

  const allMeters = useSelector((state) => state.meters.data);
  const allInverters = useSelector((state) => state.inverters.data);
  const allSensors = useSelector((state) => state.sensors.data);

  const [totals, setTotals] = useState({
    actual: 0,
    estimated: 0,
    irradiance: 0,
  });
  const [meters, setMeters] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [inverterEfficiency, setInverterEfficiency] = useState(0.96);
  const [systemLosses, setSystemLosses] = useState(0.045);
  const [tempCoefficient, setTempCoefficient] = useState(-0.004);

  useEffect(() => {
    if (fetch) {
      Promise.all(
        concat(
          map(meters, async (meter) => {
            return {
              meter_id: meter.meter_id,
              records: await new WebAPIClient().GET(
                `/resource/timestream/${meter.meter_id}/${range.start}/${range.end}`
              ),
            };
          }),
          map(sensors, async (sensor) => {
            return {
              sensor_id: sensor.sensor_id,
              records: await new WebAPIClient().GET(
                `/resource/timestream/${sensor.sensor_id}/${range.start}/${range.end}`
              ),
            };
          })
        )
      )
        .then((payload) => {
          setRawData(payload);
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setFetch(false);
        });
    }

    /* eslint-disable-next-line */
  }, [fetch]);

  // set default chart data
  useEffect(() => {
    if (!isEmpty(meters) && !isEmpty(sensors) && isEmpty(rawData)) {
      setFetch(true);
    }
    /* eslint-disable-next-line */
  }, [meters, sensors]);

  useEffect(() => {
    if (!isEmpty(site)) {
      let [siteMeters, siteInverters, siteSensors] = getSiteInvertersAndSensors(
        site,
        allMeters,
        allInverters,
        allSensors
      );

      siteSensors = map(siteSensors, (sensor) => {
        let [DCSize, ACSize] = reduce(
          siteInverters,
          (acc, inverter) => {
            if (inverter.sensor_id === sensor.sensor_id) {
              let inverterDCSize = get(inverter, 'dc_size', 0);
              let inverterACSize = get(inverter, 'ac_size', 0);
              return [acc[0] + inverterDCSize, acc[0] + inverterACSize];
            } else return acc;
          },
          [0, 0]
        );
        return {
          ...sensor,
          DCSize,
          ACSize,
        };
      });

      setMeters(siteMeters);
      setSensors(siteSensors);
    }
    /* eslint-disable-next-line */
  }, [site]);

  useEffect(() => {
    if (!isEmpty(rawData) && range) {
      setChartData(
        prepareEstimatedProductionTimeseriesData(
          rawData,
          range,
          sensors,
          inverterEfficiency,
          systemLosses,
          tempCoefficient
        )
      );
    }

    // eslint-disable-next-line
  }, [rawData, inverterEfficiency, systemLosses, tempCoefficient]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      setTotals(calculateTotals(chartData));
    }
  }, [chartData]);

  const getColor = (key) => {
    switch (key) {
      case 'production':
        return theme.palette.veregy_colors.blue;

      case 'powerEstimate':
        return theme.palette.veregy_colors.green;

      case 'radiation':
        return theme.palette.veregy_colors.darkYellow;

      case 'temp':
        return theme.palette.veregy_colors.orange;

      default:
        break;
    }
    return theme.palette.veregy_colors.blue;
  };

  const renderContent = () => {
    if (fetch) {
      return (
        <div
          style={{
            height: '450px',
          }}>
          <Loader height={150} width={150} />
        </div>
      );
    }
    if (isEmpty(chartData)) {
      return (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography>Click "Generate" to fetch chart data</Typography>
        </div>
      );
    } else {
      return (
        <ResponsiveContainer
          key={`production-profile-chart-responsive-container`}
          width='100%'
          height={400}>
          <ComposedChart
            width='100%'
            height={400}
            data={chartData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 10,
            }}>
            <YAxis
              mirror
              yAxisId='left'
              domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
              tickFormatter={(val, _axis) => {
                if (val === 0) return '';
                return numeral(val.toPrecision(4)).format('0,0.[000]') + ' kW';
              }}
            />
            <YAxis
              mirror
              yAxisId='right'
              width={100}
              orientation='right'
              domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
              tickFormatter={(val, _axis) => {
                if (val === 0) return '';
                return (
                  numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2'
                );
              }}
            />
            <XAxis
              type='number'
              dataKey='timestamp'
              domain={[range.start, range.end]}
              tickFormatter={(unixTime) =>
                dayjs(unixTime)
                  .utcOffset(get(timezone, 'offset', 0))
                  .format('MM/DD h:mm A')
              }
              tickMargin={10}
            />

            <Tooltip content={<ChartTooltip timezone={timezone} />} />

            {map(AREAS, (area) => {
              return (
                <Area
                  yAxisId={get(area, 'yAxisId')}
                  key={get(area, 'key')}
                  name={get(area, 'name')}
                  type='monotone'
                  dataKey={get(area, 'key')}
                  stroke={getColor(get(area, 'key'))}
                  fill={getColor(get(area, 'key'))}
                  fillOpacity={0.7}
                  unit={'W/m^2'}
                />
              );
            })}

            {map(LINES, (line) => {
              return (
                <Line
                  key={get(line, 'key')}
                  yAxisId={get(line, 'yAxisId')}
                  name={get(line, 'name')}
                  type='monotone'
                  dataKey={get(line, 'key')}
                  stroke={getColor(get(line, 'key'))}
                  strokeWidth={2}
                  unit={'kW'}
                  dot={false}
                />
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
  };

  return (
    <>
      <MuiGrid item xs={12} key={site.site_id}>
        <Card raised>
          <CardHeader
            sx={{ '& .MuiCardHeader-content': { display: 'flex' }, pb: 1 }}
            title={site.name}
            action={
              <Stack
                direction='row'
                mr={2}
                spacing={2}
                justifyContent='flex-end'
                divider={<Divider orientation='vertical' flexItem />}>
                <SelectNumber
                  value={inverterEfficiency}
                  setValue={setInverterEfficiency}
                  label='Inverter Efficiency'
                />
                <SelectNumber
                  value={systemLosses}
                  setValue={setSystemLosses}
                  label='System Losses'
                />
                <SelectNumber
                  value={tempCoefficient}
                  setValue={setTempCoefficient}
                  label='Temperature Coefficient'
                />
                <GenerateCSVButton
                  generateCsvString={() => prepareCsvData(chartData)}
                  filename={`${
                    site.name
                  } Estimated Production (${dayjs().format(
                    'ddd MMM DD YYYY'
                  )})`}
                />
              </Stack>
            }
          />
          <CardContent sx={{ p: 1, pb: '8px !important' }}>
            {renderContent()}
          </CardContent>
        </Card>
      </MuiGrid>
      <MuiGrid item xs={12} key={site.site_id + '-totals'}>
        <Card raised>
          <CardContent sx={{ p: '12px', pb: '12px !important' }}>
            <Stack direction='row'>
              <Typography sx={{ mr: 2 }} color='text.secondary'>
                Actual Production:
              </Typography>
              <Typography sx={{ mr: 15 }}>
                {displaykW(totals.actual, true)}
              </Typography>
              <Typography sx={{ mr: 2 }} color='text.secondary'>
                Estimated Production:
              </Typography>
              <Typography sx={{ mr: 15 }}>
                {displaykW(totals.estimated, true)}
              </Typography>
              <Typography sx={{ mr: 2 }} color='text.secondary'>
                Total Irradiance:
              </Typography>
              <Typography>
                {numeral(totals.irradiance.toPrecision(4)).format('0,0.[000]') +
                  ' Wh/m^2'}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </MuiGrid>
    </>
  );
}

EstimatedChart.propTypes = {
  site: PropTypes.object,
  range: PropTypes.object,
  timezone: PropTypes.object,
  fetch: PropTypes.bool,
  setFetch: PropTypes.func,
};

export default EstimatedChart;
