import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DialogTitleOptions from './DialogTitleOptions';

function BaseDialog(props) {
  const {
    id,
    title,
    disabled,
    handleClose,
    submitText = 'Save',
    handleSubmit = null,
    titleOptions = null,
    children,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>
        {title}
        <DialogTitleOptions handleClose={handleClose}>
          {titleOptions}
        </DialogTitleOptions>
      </DialogTitle>
      <DialogContent sx={theme.dialog.form}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {handleSubmit && (
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={disabled}>
            {submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default BaseDialog;
