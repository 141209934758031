import React from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ROUTES from '../../../constants/routes';
import NavTree from './NavTree';

const drawerMixin = (theme, expanded) => ({
  width: expanded ? theme.drawer.width.expanded : theme.drawer.width.collapsed,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: expanded
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !['expanded'].includes(prop),
})(({ theme, expanded }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...drawerMixin(theme, expanded),
  '& .MuiDrawer-paper': {
    ...drawerMixin(theme, expanded),
    scrollbarWidth: 'thin',
  },
}));

function CustomDrawer() {
  const dispatch = useDispatch();
  const { drawerExpanded } = useSelector((state) => state.app);

  return (
    <nav aria-label='drawer'>
      <StyledDrawer variant='permanent' expanded={drawerExpanded}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}>
          <Box
            component='img'
            alt='LightLevel By Veregy'
            src='/images/LightLevel_by_Veregy.svg'
            title='LightLevel By Veregy'
            sx={{ px: 2, py: 1 }}
          />
          <Divider />
          <NavTree />
          <Divider />
          <div style={{ flex: 1 }} />
          <Divider />

          <List dense={true} style={{ margin: '0px', padding: '0px' }}>
            <ListItemButton
              dense={true}
              divider={true}
              onClick={() => dispatch(push(ROUTES.AUTH.PROFILE))}>
              <ListItemIcon style={{ minWidth: '34px' }}>
                <FontAwesomeIcon icon={['fal', 'user-circle']} size='lg' />
              </ListItemIcon>
              <ListItemText
                primary='Profile'
                primaryTypographyProps={{ color: 'text.secondary' }}
              />
            </ListItemButton>
            <ListItem dense={true} divider={true}>
              <ListItemText secondary='LightLevel' />
              <ListItemText
                secondary='Terms'
                onClick={() => dispatch(push(ROUTES.AUTH.TERMS))}
                sx={{
                  cursor: 'pointer',
                }}
              />
              <ListItemText
                secondary='Privacy'
                onClick={() => dispatch(push(ROUTES.AUTH.PRIVACY))}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </ListItem>
          </List>
        </Box>
      </StyledDrawer>
    </nav>
  );
}

export default CustomDrawer;
