import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { today } from '../../../helpers/dates';
import { chartColor } from '../../../styles/muiTheme';
import { compileChartData } from './prep-data';
import Loader from '../../Loaders/ComponentLoader';
import GeneralTooltip from '../tooltips/GeneralTooltip';
import ExternalTooltip from './ExternalTooltip';

function ChartContainer({
  site,
  dataKeys,
  timezone,
  setTimezone,
  range,
  setRange,
  loading,
  chartData,
  setChartData,
  rawData,
  fetchChartData,
}) {
  const [focusLine, setFocusLine] = useState(null);
  const [selectedPayload, setSelectedPayload] = useState(null);

  // set initial timezone and range
  useEffect(() => {
    if (!isEmpty(site.timezone)) {
      setTimezone(site.timezone);
      setRange(today(site.timezone.offset));
    }
  }, [site.timezone, setTimezone, setRange]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(rawData)) {
      setChartData(compileChartData(rawData));
    }
  }, [site, range, rawData, setChartData]);

  // set default chart data
  useEffect(() => {
    if (rawData === null && range.label) {
      fetchChartData(range);
    }
    /* eslint-disable-next-line */
  }, [rawData, range]);

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardContent
            sx={{
              flex: '1 1 auto',
              overflowY: 'auto',
              minHeight: '0px',
              width: '100%',
              height: '100%',
            }}>
            {isEmpty(chartData) && !loading ? (
              <div style={{ height: '400px', textAlign: 'center' }}>
                Data Unavailable
              </div>
            ) : loading ? (
              <div style={{ height: '400px' }}>
                <Loader height={150} width={150} />
              </div>
            ) : (
              <ResponsiveContainer
                key='sensor-chart'
                width='100%'
                height={400}
                style={{
                  marginBottom: 2,
                  '& .recharts-surface': { overflow: 'visible' },
                }}>
                <LineChart
                  width='100%'
                  height={400}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 0,
                    bottom: 0,
                  }}
                  onClick={(e) => {
                    setSelectedPayload(e);
                    if (e?.activeTooltipIndex) {
                      setFocusLine(e.activeTooltipIndex);
                    } else {
                      setFocusLine(null);
                    }
                  }}>
                  <YAxis
                    mirror
                    domain={[
                      (dataMin) => Math.min(0, dataMin),
                      (dataMax) => Math.ceil(dataMax / 10) * 10,
                    ]}
                    tickFormatter={(val, _axis) => {
                      if (val === 0) return '';
                      return numeral(val.toPrecision(4)).format('0,0.[000]');
                    }}
                  />
                  <XAxis
                    type='number'
                    tickMargin={10}
                    dataKey='timestamp'
                    domain={[range.start / 1000, range.end / 1000]}
                    tickFormatter={(unixTime) => {
                      return dayjs(unixTime * 1000)
                        .utcOffset(timezone.offset)
                        .format('MM/DD h:mm A');
                    }}
                  />

                  <Tooltip content={<GeneralTooltip timezone={timezone} />} />

                  {map(dataKeys, (dataKey, idx) => {
                    return (
                      <Line
                        dot={false}
                        key={dataKey}
                        type='monotone'
                        dataKey={dataKey}
                        stroke={chartColor(idx)}
                        connectNulls
                      />
                    );
                  })}
                  {focusLine && (
                    <ReferenceLine x={chartData[focusLine]?.timestamp} />
                  )}
                </LineChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
      <ExternalTooltip selectedPayload={selectedPayload} timezone={timezone} />
    </>
  );
}

ChartContainer.propTypes = {
  site: PropTypes.object,
  dataKeys: PropTypes.array,
  timezone: PropTypes.object,
  setTimezone: PropTypes.func,
  range: PropTypes.object,
  setRange: PropTypes.func,
  loading: PropTypes.bool,
  chartData: PropTypes.array,
  setChartData: PropTypes.func,
  rawData: PropTypes.array,
};

export default ChartContainer;
