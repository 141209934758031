import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import unset from 'lodash/unset';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import MuiGrid from '@mui/material/Grid';

import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { EditingState } from '@devexpress/dx-react-grid';

import ROLES from '../../../../../constants/roles';
import useSite from '../../../../../store/hooks/useSite';
import useVerifyOrgRole from '../../../../../store/hooks/useVerifyOrgRole';
import { putSite } from '../../../../../store/sites/_sites';
import { default as TableHeader } from '../../../../../components/table/Header';
import { HeaderEditCell } from './HeaderEditCell';
import { EditCell, HeaderCell } from '../../../../../components/table/cells';

export const TYPES = {
  MODELED: 'modeled_expectations',
  INSOLATION: 'insolation_expectations',
};

const prepExpectations = (expectations) => {
  const years = reduce(
    expectations,
    (acc, monthData) => {
      return acc.add(monthData.year);
    },
    new Set()
  );

  return map(Array.from(years), (year) => {
    let values = filter(expectations, { year });
    values = reduce(
      values,
      (acc, value) => {
        return {
          ...acc,
          [value.month]: value.value,
        };
      },
      {}
    );
    return {
      year,
      ...values,
    };
  });
};

function SiteExpectationsTable() {
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const isOrgEditor = useVerifyOrgRole(site?.org_id, ROLES.EDITOR.value);
  const [disabledButton, setDisabledButton] = useState(true);
  const [expectationType, setExpectationType] = useState(TYPES.MODELED);
  const [expectations, setExpectations] = useState([]);

  let columns = [
    { title: 'Year', name: 'year' },
    { title: 'January', name: 1 },
    { title: 'February', name: 2 },
    { title: 'March', name: 3 },
    { title: 'April', name: 4 },
    { title: 'May', name: 5 },
    { title: 'June', name: 6 },
    { title: 'July', name: 7 },
    { title: 'August', name: 8 },
    { title: 'September', name: 9 },
    { title: 'October', name: 10 },
    { title: 'November', name: 11 },
    { title: 'December', name: 12 },
  ];

  const [tableColumnExtensions] = useState([
    { columnName: 'year', width: '6%', align: 'left' },
    { columnName: 1, width: '7%', align: 'right' },
    { columnName: 2, width: '8%', align: 'right' },
    { columnName: 3, width: '7%', align: 'right' },
    { columnName: 4, width: '7%', align: 'right' },
    { columnName: 5, width: '7%', align: 'right' },
    { columnName: 6, width: '7%', align: 'right' },
    { columnName: 7, width: '7%', align: 'right' },
    { columnName: 8, width: '7%', align: 'right' },
    { columnName: 9, width: '8%', align: 'right' },
    { columnName: 10, width: '8%', align: 'right' },
    { columnName: 11, width: '8%', align: 'right' },
    { columnName: 12, width: '8%', align: 'right' },
  ]);

  const [editingStateColumnExtensions] = useState([
    { columnName: 'year', editingEnabled: false },
  ]);

  useEffect(() => {
    if (id && !isEmpty(site)) {
      setExpectations(prepExpectations(get(site, expectationType)));
    }
  }, [id, site, expectationType]);

  const handleReset = () => {
    setExpectations(prepExpectations(get(site, expectationType)));
    setDisabledButton(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let _expectations = [];

    forEach(expectations, (yearData) => {
      let year = yearData.year;
      unset(yearData, 'year');
      forEach(yearData, (value, month) => {
        _expectations.push({
          year,
          month: Number(month),
          value: Number(value),
        });
      });
    });

    dispatch(putSite({ ...site, [expectationType]: _expectations }));
  };

  const handleSelectExpectationsType = (e) => {
    setExpectationType(e.target.value);
  };

  const handleInsertYear = () => {
    let _expectations = cloneDeep(expectations);
    setDisabledButton(false);

    let years = map(expectations, (yearData) => {
      return yearData.year;
    });

    if (years.length) {
      const lastYear = Math.max(
        ...map(expectations, (yearData) => {
          return yearData.year;
        })
      );

      let nextYearExpectations = find(expectations, { year: lastYear });

      forEach(Object.keys(nextYearExpectations), (key) => {
        if (key !== 'year') {
          nextYearExpectations[key] = Math.round(
            nextYearExpectations[key] * 0.995
          );
        }
      });
      _expectations.push({
        ...nextYearExpectations,
        year: lastYear + 1,
      });
    } else {
      _expectations.push({ year: Number(dayjs().format('YYYY')) });
    }
    setExpectations(sortBy(_expectations, ['year']));
  };

  const handleDeleteYear = (year) => {
    setDisabledButton(false);
    let _expectations = cloneDeep(expectations);
    remove(_expectations, { year });
    setExpectations(_expectations);
  };

  const commitChanges = ({ changed }) => {
    setDisabledButton(false);
    let changedRows;
    if (changed) {
      changedRows = map(expectations, (row, idx) => {
        if (changed[idx]) {
          return { ...row, ...changed[idx] };
        } else {
          return row;
        }
      });
    }
    setExpectations(changedRows);
  };

  const Cell = useCallback(
    (props) => {
      const {
        tableRow: { rowId },
        column: { name: columnName },
      } = props;

      const _expectations = prepExpectations(get(site, expectationType));

      const oldValue = get(_expectations, `${rowId}.${columnName}`);
      const newValue = get(expectations, `${rowId}.${columnName}`);

      const style =
        oldValue !== newValue
          ? {
              borderBottom: '2px solid red',
            }
          : {};

      return <Table.Cell {...props} style={{ ...style, padding: 4 }} />;
    },
    [expectations, site, expectationType]
  );

  return (
    <MuiGrid item xs={12}>
      <Card raised sx={{ p: 1 }}>
        <CardHeader
          disableTypography
          title={
            <Select
              sx={{ width: '25%' }}
              variant='standard'
              id='expectations-select'
              value={expectationType}
              onChange={handleSelectExpectationsType}
              MenuProps={{ MenuListProps: { disablePadding: true } }}>
              <MenuItem value={TYPES.MODELED}>Modeled Expectations</MenuItem>
              <MenuItem value={TYPES.INSOLATION}>
                Insolation Expectations
              </MenuItem>
            </Select>
          }
          action={
            <Typography>
              units: {expectationType === TYPES.MODELED ? 'kWh' : 'kWh/m2'}
            </Typography>
          }
        />
        <Grid rows={expectations} columns={columns}>
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
          />
          <TableHeaderRow
            contentComponent={TableHeader}
            cellComponent={HeaderCell}
          />
          <TableInlineCellEditing selectTextOnEditStart />
          <TableEditColumn
            width={42}
            showEditCommand
            cellComponent={(props) => (
              <EditCell
                disabled={!isOrgEditor}
                tooltipText='Delete Year'
                handleClick={() => handleDeleteYear(props.row.year)}
                icon={['fal', 'trash']}
                {...props}
              />
            )}
            headerCellComponent={(props) => {
              return (
                <HeaderEditCell
                  disabled={!isOrgEditor}
                  handleInsertYear={handleInsertYear}
                  expectations={expectations}
                  {...props}
                />
              );
            }}
          />
        </Grid>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={disabledButton} size='small' onClick={handleReset}>
            Reset
          </Button>
          <Button
            disabled={disabledButton || !isOrgEditor}
            size='small'
            variant='contained'
            onClick={handleSubmit}>
            Update
          </Button>
        </CardActions>
      </Card>
    </MuiGrid>
  );
}

export default SiteExpectationsTable;
