import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import MembershipsTable from '../../../../components/table/tables/MembershipsTable';

export default function Memberships() {
  const { id: portfolioId } = useSelector((state) => state.pages.portfolio);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', maxWidth: 1000, px: 2 }}>
      <Grid item xs={12}>
        <MembershipsTable orgId={portfolioId} />
      </Grid>
    </Grid>
  );
}
