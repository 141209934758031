import React from 'react';

import Grid from '@mui/material/Grid';

import ROLES from '../constants/roles';
import useVerifyOrgRole from '../store/hooks/useVerifyOrgRole';
import AlarmsConfigurationTable from './table/tables/AlarmsConfigurationTable';

export default function AlarmConfigurationPage(props) {
  const { resource } = props;
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, ROLES.EDITOR.value);
  const typeSelect = !['inverter', 'sensor'].includes(resource?.type_);
  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', maxWidth: 1000, px: 2 }}>
      <AlarmsConfigurationTable
        resource={resource}
        typeSelect={typeSelect}
        isOrgEditor={isOrgEditor}
      />
    </Grid>
  );
}
