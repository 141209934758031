import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import useSensor from '../../../../store/hooks/useSensor';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import SensorChart from '../../../../components/charts/dashboard/SensorChart';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import SensorInfo from './SensorInfo';
import ViewStack from '../../../../components/ViewStack';
import LatestData from './LatestData';

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function SensorDashboard() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);
  const [selectedView, setSelectedView] = useState(TREND);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', px: 2 }}>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <SensorInfo sensor={sensor} />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      {selectedView === TREND && (
        <Grid item xs={12}>
          <Card raised sx={{ p: 1 }}>
            <SensorChart
              height={250}
              site={site}
              sensor={sensor}
              hideAxisLabels={false}
            />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <AlarmsTable resource={sensor} alarms={alarms} />
      )}
      {selectedView === LATEST && (
        <Grid item xs={12}>
          <LatestData />
        </Grid>
      )}
    </Grid>
  );
}

export default SensorDashboard;
