import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { prepareSensorTimeseriesData } from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import { navigate } from '../../../../store/pages';

function SensorChart({ height = 175, site, sensor, hideAxisLabels = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [range, setRange] = useState({});
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      let start = dayjs
        .utc()
        .startOf('day')
        .subtract(site.timezone.offset, 'hour')
        .subtract(1, 'day')
        .valueOf();
      let end = dayjs
        .utc()
        .endOf('day')
        .subtract(site.timezone.offset, 'hour')
        .valueOf();

      setRange({ start, end });
    }
  }, [site]);
  // set rawData
  useEffect(() => {
    if (!isEmpty(sensor)) {
      setRawData({
        sensor_id: sensor.sensor_id,
        records: sensor.timeseries,
      });
    }
  }, [sensor]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(rawData)) {
      setChartData(
        prepareSensorTimeseriesData(rawData, range),
        [sensor],
        site.timezone.offset
      );
    }
  }, [site, sensor, range, rawData]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'sensor',
        id: sensor.sensor_id,
        tab: 'analysis',
      })
    );
  };

  const timezoneOffset = get(site, 'timezone.offset', 0);
  const start = dayjs()
    .utcOffset(timezoneOffset)
    .startOf('day')
    .subtract(1, 'day');
  const end = start.utcOffset(timezoneOffset).add(2, 'day');

  let startReference = start.add(3, 'hour');
  let endReference = start.add(27, 'hour');
  return (
    <ResponsiveContainer
      key={`chart-${sensor.sensor_id}`}
      width='100%'
      height={height}
      style={{ marginBottom: theme.spacing(2) }}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 0,
        }}>
        <YAxis
          mirror
          yAxisId='temp'
          orientation='right'
          domain={[
            (dataMin) => Math.min(0, dataMin),
            (dataMax) => Math.ceil(dataMax / 10) * 10,
          ]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' °C';
          }}
          hide={hideAxisLabels}
        />
        <YAxis
          mirror
          yAxisId='radiation'
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          width={100}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2';
          }}
          hide={hideAxisLabels}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          yAxisId='radiation'
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          yAxisId='radiation'
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft' offset={20}>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip content={<ChartTooltip timezone={site.timezone} />} />
        <Area
          yAxisId='radiation'
          key={`radiation-area-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:Radiation`}
          stroke={theme.palette.veregy_colors.darkYellow}
          fill={theme.palette.veregy_colors.darkYellow}
          fillOpacity={0.7}
        />
        <Line
          yAxisId='temp'
          dot={false}
          key={`temp-line-${sensor.sensor_id}`}
          dataKey={`${sensor.sensor_id}:PanelTemp`}
          stroke={theme.palette.veregy_colors.purple}
          connectNulls
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

SensorChart.propTypes = {
  site: PropTypes.object,
  sensor: PropTypes.object,
  hideAxisLabels: PropTypes.bool,
};

export default SensorChart;
