import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import useSensor from '../../../store/hooks/useSensor';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import SensorDashboard from './Dashboard';

import Analysis from './Analysis';
import useSensors from '../../../store/hooks/useSensors';
import usePageId from '../../../store/hooks/usePageId';
import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';

const PAGE = 'sensor';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Sensor() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(DASHBOARD);
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  usePageId(id, PAGE);
  useSensors(id);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(DASHBOARD);
    }
  }, [location.hash]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.SENSOR + '#' + value));
  };

  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <SensorDashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={sensor} />
    </TabBar>
  );
}
