import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import ROLES from '../../../constants/roles';
import { closeDialog } from '../../../store/dialogs';
import { putInverter } from '../../../store/inverters';
import useInverter, { defaultState } from '../../../store/hooks/useInverter';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';
import SelectSensor from './SelectSensor';
import ClipboardField from '../../ClipboardField';

function InverterDialog() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.dialogs.inverter);
  const inverterItem = useInverter(id);
  const [inverter, setInverter] = useState(defaultState);
  const isOrgEditor = useVerifyOrgRole(inverter?.org_id, ROLES.EDITOR.value);

  useEffect(() => {
    setInverter(inverterItem);
  }, [inverterItem]);

  const handleChange = (e) => {
    setInverter((prevInverter) => ({
      ...prevInverter,
      [e.target.id]: e.target.value,
    }));
  };

  const handleClose = () => {
    dispatch(closeDialog('inverter'));
    setInverter(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putInverter(inverter));
    handleClose();
  };

  return (
    <BaseDialog
      id={id}
      title='Update Inverter'
      disabled={!isOrgEditor}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='inverter-id-value'
              value={inverter.inverter_id}
              label='Inverter ID'
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label={`Inverter ${inverter.parent_index} Name`}
            value={inverter.name}
            onChange={handleChange}
          />
          <ListItem disableGutters>
            <FormControl id='manufacturer' variant='standard' fullWidth>
              <InputLabel id='manufacturer-label'>Manufacturer</InputLabel>
              <Select
                labelId='manufacturer-label'
                name='manufacturer'
                value={get(inverter, 'manufacturer', '')}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''>None</MenuItem>
                {[
                  'Chint Power Systems',
                  'Canadian Solar Inc',
                  'SMA Solar Technology',
                ].map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <TextFieldListItem
            id='serial_number'
            label='Serial Number'
            value={get(inverter, 'serial_number', '')}
            onChange={handleChange}
          />
          <IntegerFieldListItem
            id='dc_size'
            label='DC Size'
            value={inverter.dc_size || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
            }}
          />
          <IntegerFieldListItem
            id='ac_size'
            label='AC Size'
            value={inverter.ac_size || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
            }}
          />
          <SelectSensor
            sensorId={get(inverter, 'sensor_id', '')}
            orgId={inverter.org_id}
            handleSelect={(e) => {
              handleChange({
                target: {
                  id: 'sensor_id',
                  value: e.target.value,
                },
              });
            }}
          />
        </List>
      </form>
    </BaseDialog>
  );
}

export default InverterDialog;
