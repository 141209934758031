import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import first from 'lodash/first';

import useTheme from '@mui/material/styles/useTheme';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChangePasswordForm from './ChangePasswordForm';
import APIInfo from './APIInfo';
import AlarmsConfigurationTable from '../../../components/table/tables/AlarmsConfigurationTable';
import ReportSubscriptionsTable from './ReportSubscriptionsTable';
import { toggleThemeMode } from '../../../store/user';
import { useLocation } from 'react-router';
import { push } from 'redux-first-history';
import ROUTES from '../../../constants/routes';
import TabBar from '../../../components/tab/TabBar';
import UserMembershipsList from './UserMembershipsList';

const API = 'api';
const MEMBERSHIPS = 'memberships';
const ALARMS = 'alarms';
const CHANGE_PASSWORD = 'change-password';
const REPORTS = 'reports';
const TABS = [MEMBERSHIPS, ALARMS, CHANGE_PASSWORD];

const TabContainer = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { item: user } = useSelector((state) => state.user);

  const handleAvatarClick = () => {
    dispatch(toggleThemeMode());
  };

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', width: '100%' }}>
      <Grid item xs={12} display='flex' justifyContent='center' my={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          sx={{ width: '100%', maxWidth: 400 }}>
          <Avatar
            sx={{ bgcolor: theme.palette.veregy_colors.grey }}
            onClick={handleAvatarClick}>
            {first(get(user, 'name'))}
          </Avatar>
          <Typography variant='body1' color='text.secondary'>
            {user?.name}
          </Typography>
          <Typography variant='body1' color='text.secondary'>
            {user?.email}
          </Typography>
        </Stack>
      </Grid>
      {children}
    </Grid>
  );
};

export default function Profile() {
  const dispatch = useDispatch();
  const location = useLocation();

  const organizations = useSelector((state) => state.organizations.data);
  const { item: user, memberships } = useSelector((state) => state.user);
  const [selectedTab, setSelectedTab] = useState(MEMBERSHIPS);
  const [tabs, setTabs] = useState(TABS);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(tabs, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(MEMBERSHIPS);
    }
  }, [location.hash, tabs]);

  useEffect(() => {
    let _tabs = [...TABS];
    if (user && 'api' in user && !_tabs.includes(API)) {
      _tabs.push(API);
    }
    let hasReporting = find(memberships, (membership) => {
      const org = find(organizations, { org_id: membership.org_id });
      return org.licenses.reporting;
    });
    if (hasReporting) {
      if (!_tabs.includes(REPORTS)) {
        _tabs.push(REPORTS);
      }
    }
    setTabs(_tabs);
  }, [user, memberships, organizations]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.PROFILE + '#' + value));
  };

  return (
    <TabBar
      tabs={tabs}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <TabContainer tab={MEMBERSHIPS}>
        <UserMembershipsList />
      </TabContainer>
      <TabContainer tab={ALARMS}>
        <AlarmsConfigurationTable
          resource={user}
          orgSelect={true}
          typeSelect={true}
        />
      </TabContainer>
      <TabContainer tab={CHANGE_PASSWORD}>
        <ChangePasswordForm />
      </TabContainer>
      <TabContainer tab={API}>
        <APIInfo />
      </TabContainer>
      <TabContainer tab={REPORTS}>
        <ReportSubscriptionsTable />
      </TabContainer>
    </TabBar>
  );
}
