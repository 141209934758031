import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import remove from 'lodash/remove';
import toArray from 'lodash/toArray';
import isNull from 'lodash/isNull';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { getLatestInterval } from '../../helpers/dates';

const getInverters = createAsyncThunk(
  'inverters/getInverters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().inverters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    const inverters = await new WebAPIClient().GET('/resource/inverters');
    return { data: inverters };
  }
);

const refreshInverters = createAsyncThunk(
  'inverters/refreshInverters',
  async (inverterIds, { getState, dispatch, requestId }) => {
    const { data: inverters, loading, currentRequestId } = getState().inverters;
    const { data: loggers } = getState().loggers;
    const { data: meters } = getState().meters;
    let allInverters = cloneDeep(inverters);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let _inverters = filter(
        map(toArray(inverterIds), (inverterId) => {
          return find(inverters, { inverter_id: inverterId });
        }),
        (inverter) => {
          const meter = find(meters, { meter_id: inverter.meter_id });
          const logger = find(loggers, { logger_id: meter.logger_id });
          if (isNull(inverter) || !logger.active) return false;

          let lastRefresh = get(inverter, 'lastRefresh');
          return !lastRefresh || lastRefresh.isBefore(latestInterval);
        }
      );

      // refresh inverters
      if (_inverters.length > 0) {
        console.info(
          `REFRESH :: ${_inverters.length} INVERTERS ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        dispatch(showLoading());
        let resolvedInverters = await Promise.all(
          map(_inverters, async (inverter) => {
            const _inverter = await new WebAPIClient().GET(
              `/resource/refresh_inverter/${inverter.org_id}/${inverter.inverter_id}`
            );

            return {
              ..._inverter,
              lastRefresh: dayjs(),
            };
          })
        ).then((updatedInverters) => {
          each(updatedInverters, (updatedInverter) => {
            remove(allInverters, {
              inverter_id: get(updatedInverter, 'inverter_id'),
            });
          });

          return concat(allInverters, updatedInverters);
        });
        return { data: resolvedInverters };
      }
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putInverter = createAsyncThunk(
  'inverters/putInverter',
  async (inverter, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: inverters,
      } = getState().inverters;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedInverter = await new WebAPIClient().PUT(
        `/resource/inverters/${inverter.org_id}/${inverter.inverter_id}`,
        inverter
      );

      let _inverters = cloneDeep(inverters);
      remove(_inverters, { inverter_id: get(updatedInverter, 'inverter_id') });

      toastr.success('Inverter updated');
      return { data: concat(_inverters, updatedInverter) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getInverters, refreshInverters, putInverter };
