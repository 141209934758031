import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { navigate } from '../../../../store/pages';
import useMeter from '../../../../store/hooks/useMeter';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import MeterProfileChart from '../../../../components/charts/preview/MeterProfileChart';
import { openDialog } from '../../../../store/dialogs';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';

function MeterCard({ meterId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const meter = useMeter(meterId);
  const alarms = useDeviceAlarms(meterId);

  const handleMeterClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meter.meter_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'meter',
        mode: 'edit',
        id: meter.meter_id,
      })
    );
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Box
      sx={{
        px: 1,
        pt: 1,
        pb: 0.5,
        backgroundColor: 'background.paper',
        boxShadow: theme.shadows[4],
        borderRadius: 1,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AlarmPreviewLink
          alarms={activeAlarms}
          deviceName={meter.name}
          handleClick={() => handleMeterClick()}
        />
        <IconButton onClick={() => handleIconClick()}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      </Box>
      <MeterProfileChart meterId={meter.meter_id} />
    </Box>
  );
}

MeterCard.propTypes = {
  meterId: PropTypes.string.isRequired,
};

export default MeterCard;
