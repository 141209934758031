import React from 'react';
import AlarmDialog from '../../../components/Dialogs/AlarmDialog';
import OrganizationDialog from '../../../components/Dialogs/OrganizationDialog';
import SiteDialog from '../../../components/Dialogs/SiteDialog';
import LoggerDialog from '../../../components/Dialogs/LoggerDialog';
import MeterDialog from '../../../components/Dialogs/MeterDialog';
import InverterDialog from '../../../components/Dialogs/InverterDialog';
import SensorDialog from '../../../components/Dialogs/SensorDialog';
import MembershipDialog from '../../../components/Dialogs/MembershipDialog';
import TriggerDialog from '../../../components/Dialogs/TriggerDialog';
import InfoDialog from '../../../components/Dialogs/InfoDialog';
import TriggerDeviceSubscriptionDialog from '../../../components/Dialogs/TriggerDeviceSubscriptionDialog';
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog';
import SubscribedUsersDialog from '../../../components/Dialogs/SubscribedUsersDialog';
import EmailedUsersDialog from '../../../components/Dialogs/EmailedUsersDialog';
import ReportDialog from '../../../components/Dialogs/ReportDialog';
import ReportFilesDialog from '../../../components/Dialogs/ReportFilesDialog';
import GenerateReportDialog from '../../../components/Dialogs/GenerateReportDialog';

function Dialogs() {
  return (
    <>
      <OrganizationDialog />
      <MembershipDialog />
      <SiteDialog />
      <LoggerDialog />
      <MeterDialog />
      <InverterDialog />
      <SensorDialog />
      <AlarmDialog />
      <TriggerDialog />
      <TriggerDeviceSubscriptionDialog />
      <InfoDialog />
      <ConfirmDialog />
      <SubscribedUsersDialog />
      <EmailedUsersDialog />
      <ReportDialog />
      <ReportFilesDialog />
      <GenerateReportDialog />
    </>
  );
}

export default Dialogs;
