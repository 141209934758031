import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import remove from 'lodash/remove';
import toArray from 'lodash/toArray';
import isNull from 'lodash/isNull';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { dateStringToDayJS, getLatestInterval } from '../../helpers/dates';
import { updateNodes } from '../nodes';

const getLoggers = createAsyncThunk(
  'loggers/getLoggers',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().loggers;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const loggers = await new WebAPIClient().GET('/resource/loggers');
    return { data: loggers };
  }
);

const refreshLoggers = createAsyncThunk(
  'loggers/refreshLoggers',
  async (loggerIds, { getState, dispatch, requestId }) => {
    const {
      data: loggers,
      loaded,
      loading,
      currentRequestId,
    } = getState().loggers;
    let allLoggers = cloneDeep(loggers);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let _loggers = filter(
        map(toArray(loggerIds), (loggerId) => {
          return find(loggers, { logger_id: loggerId });
        }),
        (logger) => {
          if (isNull(logger) || !logger.active) return false;

          let lastOk = dateStringToDayJS(get(logger, 'stats.last_ok_time'));
          let lastFail = dateStringToDayJS(get(logger, 'stats.last_fail_time'));

          return (
            !loaded ||
            lastOk.isBefore(latestInterval) ||
            (lastFail.isAfter(lastOk) && lastFail.isBefore(latestInterval))
          );
        }
      );

      // refresh loggers
      if (_loggers.length > 0) {
        dispatch(showLoading());
        let resolvedLoggers = await Promise.all(
          map(
            _loggers,
            async (logger) =>
              await new WebAPIClient().GET(
                `/resource/refresh_logger/${logger.org_id}/${logger.logger_id}`
              )
          )
        ).then((updatedLoggers) => {
          each(updatedLoggers, (updatedLogger) => {
            remove(allLoggers, {
              logger_id: get(updatedLogger, 'logger_id'),
            });
          });
          return concat(allLoggers, updatedLoggers);
        });
        return { data: resolvedLoggers, loaded: true };
      }
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putLogger = createAsyncThunk(
  'loggers/putLogger',
  async (logger, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: loggers } = getState().loggers;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedLogger = await new WebAPIClient().PUT(
        `/resource/loggers/${logger.org_id}/${logger.logger_id}`,
        logger
      );

      let _loggers = cloneDeep(loggers);
      remove(_loggers, { logger_id: get(updatedLogger, 'logger_id') });

      toastr.success('Logger updated');
      _loggers = concat(_loggers, updatedLogger);
      dispatch(updateNodes({ loggers: _loggers }));
      return { data: _loggers };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getLoggers, putLogger, refreshLoggers };
