import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ROLES from '../../../constants/roles';
import {
  deleteImage,
  getImages,
  postImage,
} from '../../../store/images/_images';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';

function CaseStudy(props) {
  const { resource, resourceId } = props;
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, ROLES.EDITOR.value);

  const images = useSelector((state) => get(state.images, resourceId, []));
  const storeUrls = useSelector((state) => state.images.presignedUrls);

  const [imageFile, setImageFile] = useState(null);
  const [imageBlobUrl, setImageBlobUrl] = useState(null);
  const [imageFilename, setImageFilename] = useState('');
  const [caseStudyFilename, setCaseStudyFilename] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    let _caseStudyFile = find(images, (image) =>
      image.startsWith('case-study.')
    );
    if (_caseStudyFile) {
      setCaseStudyFilename(_caseStudyFile);
    } else {
      setCaseStudyFilename('');
    }
  }, [images, resourceId, dispatch]);

  useEffect(() => {
    const resourceUrls = get(storeUrls, `${resourceId}`, {});
    if (caseStudyFilename in resourceUrls) {
      setUrl(resourceUrls[caseStudyFilename]);
    } else {
      setUrl('');
    }
  }, [storeUrls, resourceId, caseStudyFilename]);

  const handleDelete = async (e) => {
    e.preventDefault();

    if (imageBlobUrl) {
      setImageFile(null);
      setImageFilename('');
      setImageBlobUrl(null);
    } else if (url) {
      await dispatch(deleteImage({ resourceId, filename: caseStudyFilename }));
      dispatch(getImages(resourceId));
      setUrl('');
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    let _imageFile = null;
    if (e.dataTransfer) {
      _imageFile = e.dataTransfer.files[0];
    } else if (e.target.files) {
      _imageFile = e.target.files[0];
    }

    setImageFile(_imageFile);
    setImageFilename(_imageFile.name);
    setImageBlobUrl(URL.createObjectURL(_imageFile));
  };

  const handleSave = async () => {
    const lastDotIndex = imageFilename.lastIndexOf('.');
    const ext = imageFilename.slice(lastDotIndex);

    if (caseStudyFilename) {
      await dispatch(deleteImage({ resourceId, filename: caseStudyFilename }));
    }

    await dispatch(
      postImage({
        resource_id: resourceId,
        image: imageFile,
        filename: 'case-study' + ext,
      })
    );
    await dispatch(getImages(resourceId));
    setImageFile(null);
    setImageFilename('');
    setImageBlobUrl(null);
  };

  return (
    <Card raised>
      <CardHeader
        sx={{ p: 1, pb: 0 }}
        title='Case Study'
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <>
            {(url || imageBlobUrl) && (
              <IconButton disabled={!isOrgEditor} onClick={handleDelete}>
                <FontAwesomeIcon
                  icon={['fal', 'trash']}
                  style={{ height: 23, width: 23 }}
                />
              </IconButton>
            )}

            <Tooltip placement='top' title='upload'>
              <IconButton component='label' disabled={!isOrgEditor}>
                <FontAwesomeIcon icon={['fal', 'upload']} size='sm' />
                <input
                  hidden
                  accept='image/*'
                  type='file'
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
              </IconButton>
            </Tooltip>

            <IconButton
              sx={{ mr: 1 }}
              disabled={!isOrgEditor || !imageBlobUrl}
              onClick={handleSave}>
              <FontAwesomeIcon
                icon={['fal', 'save']}
                style={{ height: 23, width: 23 }}
              />
            </IconButton>
          </>
        }
      />

      <CardContent
        sx={{ p: 1, pt: 0, pb: '8px !important', textAlign: 'center' }}>
        {(imageBlobUrl || url) && (
          <img
            src={imageBlobUrl || url}
            alt={imageFilename || caseStudyFilename}
            height={125}
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(imageBlobUrl || url, '_blank')}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default CaseStudy;
