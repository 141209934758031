import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

import Grid from '@mui/material/Grid';

import { roundNumber } from '../../../../helpers';
import { getFaultMessage } from './faultAlarmMessages';
import useInverter from '../../../../store/hooks/useInverter';
import RegisterDataGrid from '../../../../components/RegisterDataGrid';
import ValuesList from '../../../../components/RegisterDataGrid/ValuesList';

export const REGISTER_OBJECTS = {
  analogValue: {
    1: { objectName: 'LifetimeEnergy' },
    2: { objectName: 'DayEnergy' },
    3: { objectName: 'PowerFactor' },
    4: { objectName: 'MaxPower' },
    5: { objectName: 'ActivePower' },
    6: { objectName: 'AppPower' },
    7: { objectName: 'GridVoltage1_2' },
    8: { objectName: 'GridVoltage2_3' },
    9: { objectName: 'GridVoltage1_3' },
    10: { objectName: 'GridCurrent1' },
    11: { objectName: 'GridCurrent2' },
    12: { objectName: 'GridCurrent3' },
    13: { objectName: 'DCVolts_MPPT1' },
    14: { objectName: 'DCAmps_MPPT1' },
    15: { objectName: 'DCVolts_MPPT2' },
    16: { objectName: 'DCAmps_MPPT2' },
    17: { objectName: 'GridFreq' },
    18: { objectName: 'HeatSinkTemp' },
    19: { objectName: 'InverterTemp' },
  },
  binaryValue: {
    5: { objectName: 'InverterAlarm' },
  },
  multiStateValue: {
    1: {
      objectName: 'InverterState',
      stateText: ['Derating', 'Running', 'Standby', 'Check', 'Fault'],
    },
    2: { objectName: 'Pfault' },
    3: { objectName: 'WarningAlarm' },
    4: { objectName: 'FaultAlarm0' },
    5: { objectName: 'FaultAlarm1' },
    6: { objectName: 'FaultAlarm2' },
    7: { objectName: 'FaultAlarm3' },
    8: { objectName: 'FaultAlarm4' },
  },
};

function LatestData(props) {
  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);

  const [data, setData] = useState([]);
  const [analogValues, setAnalogValues] = useState([]);
  const [binaryValues, setBinaryValues] = useState([]);
  const [multiStateValues, setMultiStateValues] = useState([]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.analogValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.analogValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      return {
        measure_name: valueObj.objectName,
        value: datapoint?.value ? roundNumber.format(datapoint?.value) : '-',
      };
    });
    setAnalogValues(values);
  }, [data]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.binaryValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.binaryValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      let value = '-';
      if (datapoint?.value === 0) {
        value = 'Inactive';
      }
      if (datapoint?.value === 1) {
        value = 'Active';
      }
      return {
        measure_name: valueObj.objectName,
        value,
      };
    });
    setBinaryValues(values);
  }, [data]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.multiStateValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.multiStateValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      let value = '-';
      if (!isUndefined(datapoint?.value)) {
        if (datapoint.measure_name.startsWith('InverterState')) {
          value =
            REGISTER_OBJECTS.multiStateValue[1].stateText[datapoint.value];
        } else {
          value = getFaultMessage(
            datapoint.measure_name,
            datapoint.value,
            inverter.manufacturer
          );
        }
      }
      return {
        measure_name: valueObj.objectName,
        value,
      };
    });
    setMultiStateValues(values);
  }, [data, inverter.manufacturer]);

  return (
    <RegisterDataGrid
      registerObjects={REGISTER_OBJECTS}
      resourceId={inverter.inverter_id}
      setData={setData}>
      <Grid item xs={6}>
        <ValuesList title='Analog Values' values={analogValues} />
      </Grid>
      <Grid item xs={6}>
        <ValuesList title='Binary Values' values={binaryValues} />
        <ValuesList
          title='Multi State Values'
          values={multiStateValues}
          mt={1}
        />
      </Grid>
    </RegisterDataGrid>
  );
}

export default LatestData;
