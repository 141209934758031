import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ROLES from '../../../../constants/roles';
import { setPage } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import CountGrid from '../../../../components/Grid/CountGrid';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import LoggersGrid from './LoggersGrid';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';

const LOGGERS = 'loggers';
const ALARMS = 'alarms';
const VIEWS = [LOGGERS, ALARMS];

function SiteDashboard() {
  const dispatch = useDispatch();
  const { id, view, expectationType } = useSelector(
    (state) => state.pages.site
  );
  const site = useSite(id);
  const { loggers, meters, inverters, sensors, alarms, loading } =
    useSiteDevices(id);

  const isOrgAdmin = useVerifyOrgRole(site?.org_id, ROLES.ADMIN.value);
  const [selectedView, setSelectedView] = useState(LOGGERS);

  useEffect(() => {
    if (has(VIEWS, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(LOGGERS);
    }
  }, [view]);

  const handleSetExpectationType = (type) => {
    switch (type) {
      case 'modeled':
      case 'corrected':
        dispatch(setPage({ page: 'site', expectationType: type }));
        break;

      default:
        dispatch(setPage({ page: 'site', expectationType: 'modeled' }));
        break;
    }
  };

  // const renderView = () => {
  //   if (selectedView === LOGGERS) {
  //     return <LoggersGrid site={site} loading={loading} loggers={loggers} />;
  //   } else if (selectedView === ALARMS) {
  //     return <AlarmsTable resource={site} alarms={alarms} />;
  //   }
  // };

  const activeAlarms = filter(alarms, { status: true });

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', px: 2 }}>
      <Grid item xs={12} md={4}>
        <ResourceCount loading={loading}>
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={inverters.length} name='Inverters' />
          <CountGrid number={sensors.length} name='Sensors' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid item md={4}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid item md={4}>
        <ProductionIndex
          sites={[site]}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      {selectedView === LOGGERS && (
        <LoggersGrid site={site} loading={loading} loggers={loggers} />
      )}
      {selectedView === ALARMS && (
        <Grid item xs={12}>
          <AlarmsTable resource={site} alarms={alarms} />
        </Grid>
      )}
    </Grid>
  );
}

export default SiteDashboard;
