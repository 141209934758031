import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import usePageId from '../../../store/hooks/usePageId';
import useLoggers from '../../../store/hooks/useLoggers';
import useLogger from '../../../store/hooks/useLogger';
import useMeters from '../../../store/hooks/useMeters';
import useInverters from '../../../store/hooks/useInverters';
import useSensors from '../../../store/hooks/useSensors';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Dashboard from './Dashboard';
import Analysis from './Analysis';

import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';

const PAGE = 'logger';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Logger() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(DASHBOARD);
  const { id } = useSelector((state) => state.pages.logger);
  usePageId(id, PAGE);
  const logger = useLogger(id);
  useLoggers(id);
  useMeters(id);
  useInverters(id);
  useSensors(id);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(DASHBOARD);
    }
  }, [location.hash]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.LOGGER + '#' + value));
  };
  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={logger} />
    </TabBar>
  );
}
