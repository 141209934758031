import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ROLES from '../../../../constants/roles';
import useSite from '../../../../store/hooks/useSite';
import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import ViewStack from '../../../../components/ViewStack';
import Kiosk from '../../../../components/Configuration/Kiosk';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import SiteExpectationsTable from './SiteExpectationsTable';
import ReportsTable from '../../../../components/table/tables/ReportsTable';

const ALARMS = 'alarms';
const EXPECTATIONS = 'expectations';
const KIOSK = 'kiosk';
const REPORTS = 'reports';
const VIEWS = [ALARMS, EXPECTATIONS, KIOSK];

export default function Configuration() {
  const { id, view } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const { organization } = useOrganization(site?.org_id);
  const isOrgEditor = useVerifyOrgRole(site?.org_id, ROLES.EDITOR.value);

  const [views, setViews] = useState(VIEWS);
  const [selectedView, setSelectedView] = useState(ALARMS);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(ALARMS);
    }
  }, [views, view]);

  useEffect(() => {
    if (organization?.licenses?.reporting) {
      if (!views.includes(REPORTS)) {
        setViews([...VIEWS, REPORTS]);
      }
    }
  }, [organization?.licenses?.reporting, views]);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', px: 2 }}>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      {selectedView === ALARMS && (
        <AlarmsConfigurationTable
          resource={site}
          isOrgEditor={isOrgEditor}
          typeSelect={true}
        />
      )}
      {selectedView === EXPECTATIONS && (
        <Grid item xs={12}>
          <SiteExpectationsTable />
        </Grid>
      )}
      {selectedView === KIOSK && (
        <Kiosk resourceId={site.site_id} orgId={site.org_id} />
      )}
      {selectedView === REPORTS && (
        <Grid item xs={12}>
          <ReportsTable resource={site} />
        </Grid>
      )}
    </Grid>
  );
}
