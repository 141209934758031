import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import useLoggers from '../../../store/hooks/useLoggers';
import useMeters from '../../../store/hooks/useMeters';
import useInverters from '../../../store/hooks/useInverters';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Dashboard from './Dashboard';
import Configuration from './Configuration';
import Analysis from './Analysis';
import usePageId from '../../../store/hooks/usePageId';

const PAGE = 'site';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Site() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(DASHBOARD);
  const { id } = useSelector((state) => state.pages.site);
  usePageId(id, PAGE);
  useLoggers(id);
  useMeters(id);
  useInverters(id);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(DASHBOARD);
    }
  }, [location.hash]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.SITE + '#' + value));
  };

  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <Configuration tab={CONFIGURATION} />
    </TabBar>
  );
}
