import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import map from 'lodash/map';
import each from 'lodash/each';
import range from 'lodash/range';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { addEstimatedProductionToChartData } from '../../../../helpers/chart-data';
import { navigate } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import ComponentLoader from '../../../Loaders/ComponentLoader';
import ChartTooltip from './Tooltip';

function LoggerProfileChart({ logger }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const site = useSite(logger?.site_id);
  const { meters, inverters, loading } = useLoggerDevices(logger.logger_id);

  const timezoneOffset = get(site, 'timezone.offset', 0);
  const start = dayjs()
    .utcOffset(timezoneOffset)
    .startOf('day')
    .subtract(1, 'day');
  const end = start.utcOffset(timezoneOffset).add(2, 'day');

  useEffect(() => {
    if (!isEmpty(logger)) {
      let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      _data = map(_data, (record) => {
        const timestamp = get(record, 'timestamp');
        each(meters, (meter) => {
          let meterPoint = find(meter.timeseries, { timestamp });
          if (meterPoint) {
            record[meter.meter_id] = get(meterPoint, 'value');
          }
        });

        return { ...record };
      });

      _data = map(_data, (record) => {
        const timestamp = get(record, 'timestamp');
        each(inverters, (inverter) => {
          let inverterPoint = find(inverter.timeseries, { timestamp });
          if (inverterPoint) {
            record[inverter.inverter_id] = get(inverterPoint, 'value');
          }
        });

        return { ...record };
      });

      _data = addEstimatedProductionToChartData(
        _data,
        meters,
        site?.timezone?.offset
      );
      setData(_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logger, site, inverters, meters]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'logger',
        id: logger.logger_id,
        tab: 'analysis',
      })
    );
  };

  if (loading)
    return (
      <div
        style={{
          height: '200px',
        }}>
        <ComponentLoader height='75px' width='75px' />
      </div>
    );

  let startReference = start.add(3, 'hour');
  let endReference = start.add(27, 'hour');

  const _meters = sortBy(meters, 'parent_index');
  return (
    <ResponsiveContainer width='100%' height={200}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={200}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip
          content={
            <ChartTooltip
              timezone={site.timezone}
              logger={logger}
              meters={meters}
              inverters={inverters}
            />
          }
        />
        {map(_meters, (meter, idx) => {
          return (
            <Area
              stackId='areas'
              key={`area-${meter.meter_id}`}
              type='monotone'
              dataKey={meter.meter_id}
              fill={alpha(
                theme.palette.veregy_colors.blue,
                1 - idx * (1 / Math.max(_meters.length, 4))
              )}
            />
          );
        })}
        {map(_meters, (meter, idx) => {
          return (
            <Bar
              stackId='estimated'
              key={`estimated-${meter.meter_id}`}
              dataKey={`${meter.meter_id}:estimated`}
              fill={alpha(theme.palette.veregy_colors.lightgrey, 1 - idx * 0.2)}
            />
          );
        })}

        {map(sortBy(inverters, 'parent_index'), (inverter) => {
          return (
            <Area
              dot={false}
              stackId='lines'
              key={`line-${inverter.inverter_id}`}
              dataKey={inverter.inverter_id}
              stroke={theme.palette.veregy_colors.orange}
              fill='transparent'
              type='monotone'
              connectNulls
              strokeWidth={2}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

LoggerProfileChart.propTypes = {
  meterId: PropTypes.string,
};

export default LoggerProfileChart;
