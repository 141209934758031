import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import ROUTES from '../../../constants/routes';
import { navigate } from '../../../store/pages';
import useAlarms from '../../../store/hooks/useAlarms';
import Dialogs from './Dialogs';
import CustomAppBar from './CustomAppBar';
import CustomDrawer from './CustomDrawer';

function Layout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const toolbarRef = useRef();
  useAlarms();

  useEffect(() => {
    if (location.pathname === '/app') {
      dispatch(push(ROUTES.AUTH.PORTFOLIO));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (location.search.includes('?resource=')) {
      const resource_id = location.search.split('=')[1];

      if (resource_id) {
        const type = resource_id.split(':')[0];
        dispatch(navigate({ page: type, id: resource_id }));
      }
    }
  }, [location, dispatch]);

  const marginTop = toolbarRef?.current?.offsetHeight || 64;
  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100vw' }}>
      <CssBaseline />
      <CustomAppBar ref={toolbarRef} />
      <CustomDrawer />
      <Box component='main' sx={{ marginTop: marginTop + 'px', width: '100%' }}>
        <Outlet />
      </Box>
      <Dialogs />
    </Box>
  );
}

export default Layout;
