import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { common } from '@mui/material/colors';
import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { setDrawerExpanded } from '../../../store/app';
import { logoutUser } from '../../../store/user';
import { navigate } from '../../../store/pages';
import Breadcrumbs from './Breadcrumbs';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['expanded', 'drawerWidth'].includes(prop),
})(({ expanded, theme, drawerWidth }) => ({
  boxShadow: 'unset',
  [theme.breakpoints.up('md')]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: expanded
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

const DrawerToggleButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.down('md')]: {
    marginLeft: '-4px',
  },
}));

const CustomAppBar = forwardRef((props, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerExpanded } = useSelector((state) => state.app);
  const user = useSelector((state) => state.user.item);

  const toggleExpandDrawer = () => {
    dispatch(setDrawerExpanded(!drawerExpanded));
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const drawerWidth = drawerExpanded
    ? theme.drawer.width.expanded
    : theme.drawer.width.collapsed;
  return (
    <StyledAppBar
      ref={ref}
      position='fixed'
      drawerWidth={drawerWidth}
      expanded={drawerExpanded}>
      <Toolbar>
        <DrawerToggleButton
          color='inherit'
          edge='start'
          onClick={toggleExpandDrawer}>
          <FontAwesomeIcon
            icon={['fal', drawerExpanded ? 'arrow-to-left' : 'bars']}
            size='sm'
          />
        </DrawerToggleButton>
        <Breadcrumbs />
        <Typography
          noWrap
          align='right'
          sx={{ flexGrow: 1, mr: 2 }}
          onClick={() => dispatch(navigate({ page: 'profile' }))}>
          {user?.email}
        </Typography>
        <IconButton aria-label='open popover' onClick={handleLogout}>
          <FontAwesomeIcon
            icon={['fal', 'sign-out']}
            color={common.white}
            size='sm'
          />
        </IconButton>
        <IconButton href='https://support.veregy.com/section/102-lightlevel'>
          <FontAwesomeIcon
            icon={['fal', 'question-circle']}
            color={common.white}
            size='sm'
          />
        </IconButton>
      </Toolbar>
      <Divider />
    </StyledAppBar>
  );
});

export default CustomAppBar;
