import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import WebAPIClient from '../../../../api';
import { prepareCsvData } from '../prep-data';
import SelectRange from '../../selectors/SelectRange';
import SelectAttributes from '../../selectors/SelectAttributes';
import SelectTimezone, { TIMEZONES } from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import ChartHeader from '../ChartHeader';
import ChartContainer from '../ChartContainer';

const ATTRIBUTES = [
  'kW_Demand_System_Now',
  'kW_System_Net',
  'kW_L1',
  'kW_L2',
  'kW_L3',
  'Volts_Line_To_Line_Average',
  'Volts_Line_To_Neutral_Average',
  'Volts_L1_to_L2',
  'Volts_L2_to_L3',
  'Volts_L1_to_L3',
  'Volts_L1_to_Neutral',
  'Volts_L2_to_Neutral',
  'Volts_L3_to_Neutral',
  'Amps_System_Average',
  'Amps_L1',
  'Amps_L2',
  'Amps_L3',
  'EM_Status',
  'EM_NetworkStatus',
];

function MeterChart({ site, meter }) {
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [range, setRange] = useState({ key: '', label: '', start: 0, end: 0 });
  const [attributes, setAttrbutes] = useState([ATTRIBUTES[1]]);
  const [rawData, setRawData] = useState(null);
  const [chartData, setChartData] = useState([]);

  const fetchChartData = async (range) => {
    setLoading(true);

    const payload = await new WebAPIClient().GET(
      `/resource/timestream-device/${meter.meter_id}/${range.start}/${range.end}`,
      {
        attributes: JSON.stringify(attributes),
      }
    );
    setRawData(payload);
    setLoading(false);
  };

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    fetchChartData(newRange);
  };

  return (
    <>
      <Grid item xs={12}>
        <ChartHeader
          sx={{ p: 0 }}
          title={
            <Stack
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mt: 1, mb: 1 }}
                />
              }>
              <SelectAttributes
                selectedAttributes={attributes}
                setSelectedAttributes={setAttrbutes}
                attributes={ATTRIBUTES}
              />
              <SelectRange
                range={range}
                handleRangeSelect={handleRangeSelect}
                timezone={timezone}
              />
              <SelectTimezone
                selectedTimezone={timezone}
                setSelectedTimezone={setTimezone}
              />
              <Button
                color='primary'
                variant='contained'
                size='small'
                onClick={() => fetchChartData(range)}>
                Generate
              </Button>
              <GenerateCSVButton
                generateCsvString={() =>
                  prepareCsvData(chartData, attributes, null)
                }
                filename={`${meter.name} Data (${dayjs().format(
                  'ddd MMM DD YYYY'
                )})`}
              />
            </Stack>
          }
        />
      </Grid>
      <ChartContainer
        site={site}
        dataKeys={attributes}
        timezone={timezone}
        setTimezone={setTimezone}
        range={range}
        setRange={setRange}
        loading={loading}
        chartData={chartData}
        setChartData={setChartData}
        rawData={rawData}
        fetchChartData={fetchChartData}
      />
    </>
  );
}

MeterChart.propTypes = {
  site: PropTypes.object,
  meter: PropTypes.object,
};

export default MeterChart;
