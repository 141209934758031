import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { today } from '../../../../helpers/dates';
import WebAPIClient from '../../../../api';
import { compileChartData, prepareCsvData } from '../prep-data';
import SelectRange from '../../selectors/SelectRange';
import SelectTimezone, { TIMEZONES } from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import SelectAttributes from '../../selectors/SelectAttributes';
import InfoButton from '../../buttons/InfoButton';
import ChartHeader from '../ChartHeader';
import ChartContainer from '../ChartContainer';

const ATTRIBUTES = [
  'DevType',
  'DataModel',
  'Radiation',
  'PanelTemp',
  'VSupply',
  'ErrorCode',
  'CommError',
  'OperMode',
  'Status',
];

function SensorChart({ site, sensor }) {
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [range, setRange] = useState({ key: '', name: '', start: 0, end: 0 });
  const [attributes, setAttrbutes] = useState([ATTRIBUTES[2]]);
  const [rawData, setRawData] = useState(null);
  const [chartData, setChartData] = useState([]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(site.timezone)) {
      setTimezone(site.timezone);
    }
  }, [site.timezone]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      setRange(today(site.timezone.offset));
    }
  }, [site]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(rawData)) {
      setChartData(compileChartData(rawData));
    }
  }, [site, sensor, range, rawData]);

  // set default chart data
  useEffect(() => {
    if (rawData === null && range.label) {
      fetchChartData(range);
    }
    /* eslint-disable-next-line */
  }, [rawData, range]);

  const fetchChartData = async (range) => {
    setLoading(true);

    const payload = await new WebAPIClient().GET(
      `/resource/timestream-device/${sensor.sensor_id}/${range.start}/${range.end}`,
      {
        attributes: JSON.stringify(attributes),
      }
    );

    setRawData(payload);
    setLoading(false);
  };

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    fetchChartData(newRange);
  };

  const dataKeys = map(
    attributes,
    (attribute, idx) => attribute + '_S' + sensor.parent_index
  );
  return (
    <>
      <ChartHeader
        title='Sensor Chart'
        action={
          <Stack
            direction='row'
            mr={2}
            spacing={2}
            justifyContent='flex-end'
            divider={
              <Divider orientation='vertical' flexItem sx={{ mt: 1, mb: 1 }} />
            }>
            <SelectAttributes
              selectedAttributes={attributes}
              setSelectedAttributes={setAttrbutes}
              attributes={ATTRIBUTES}
            />

            <SelectRange
              range={range}
              handleRangeSelect={handleRangeSelect}
              timezone={timezone}
            />

            <SelectTimezone
              selectedTimezone={timezone}
              setSelectedTimezone={setTimezone}
            />

            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={() => fetchChartData(range)}>
              Generate
            </Button>

            <GenerateCSVButton
              generateCsvString={() =>
                prepareCsvData(
                  chartData,
                  attributes,
                  `_S${sensor.parent_index}`
                )
              }
              filename={`${sensor.name} Data (${dayjs().format(
                'ddd MMM DD YYYY'
              )})`}
            />

            <InfoButton
              title='Sensor Chart'
              content={
                <>
                  <Typography variant='body2' color='text.secondary'>
                    Select attributes, date range, and timezone.
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Click "Generate" to fetch chart data and generate a chart.
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Click Download icon to download the data shown in the chart.
                  </Typography>
                </>
              }
            />
          </Stack>
        }
      />
      <ChartContainer
        site={site}
        dataKeys={dataKeys}
        timezone={timezone}
        setTimezone={setTimezone}
        range={range}
        setRange={setRange}
        loading={loading}
        chartData={chartData}
        setChartData={setChartData}
        rawData={rawData}
        fetchChartData={fetchChartData}
      />
    </>
  );
}

SensorChart.propTypes = {
  site: PropTypes.object,
  sensor: PropTypes.object,
};

export default SensorChart;
